import { render, staticRenderFns } from "./auto-transfer-form.vue?vue&type=template&id=4cd0d24a&scoped=true&"
import script from "./auto-transfer-form.vue?vue&type=script&lang=js&"
export * from "./auto-transfer-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cd0d24a",
  null
  
)

export default component.exports