<template>
  <div v-if="bankAccountForm.bankUseType !== BankUseType.HOLD">
    <b-form-group
        label="ยอดเงินต่ำสุดในบัญชี"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="minBalance"
    >
      <b-input-group append="บาท">
        <b-input-group-prepend is-text>
          <b-form-checkbox
              v-model="form.isEnableTransferMinBalance"
              name="isEnableMaxBalance"
              switch
          />
        </b-input-group-prepend>
        <b-input
            id="minBalance"
            v-model.number="form.minBalance"
            :disabled="!form.isEnableTransferMinBalance"
            type="number"
        ></b-input>
      </b-input-group>
    </b-form-group>
    <b-form-group
        label="ยอดเงินสูงสุดในบัญชี"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="maxBalance"
    >
      <b-input-group append="บาท">
        <b-input-group-prepend is-text>
          <b-form-checkbox
              v-model="form.isEnableTransferMaxBalance"
              name="isEnableMaxBalance"
              switch
          />
        </b-input-group-prepend>
        <b-input
            id="maxBalance"
            v-model.number="form.maxBalance"
            :disabled="!form.isEnableTransferMaxBalance"
            type="number"
        ></b-input>
      </b-input-group>
    </b-form-group>
  </div>
  <div v-else>
    <b-form-group
        description="ถ้ามีการรับเงินเข้าบัญชีพักจนถึงจำนวนเงินพักสูงสุด จะมีการส่งการแจ้งเตือนไปยังไลน์"
        label="รับเงินพักสูงสุด"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="maxHoldAmount"
    >
      <b-input-group append="บาท">
        <b-input-group-prepend is-text>
          <b-form-checkbox
              v-model="isEnableMaxHoldAmount"
              name="isEnableMaxHoldAmount"
              switch
          />
        </b-input-group-prepend>
        <b-input
            id="maxHoldAmount"
            v-model.number="form.maxHoldAmount"
            :disabled="!isEnableMaxHoldAmount"
            type="number"
        ></b-input>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import Vue from 'vue';
import {BankUseTypeEnum} from '@src/constants/bank';

export default Vue.extend({
  name: 'AutoTransferForm',
  props: {
    bankAccountForm: {
      type: Object,
      default: () => ({
        minBalance: 0,
        maxBalance: 0
      })
    }
  },
  data() {
    return {
      form: {},
      isEnableMaxHoldAmount: false
    }
  },
  computed: {
    BankUseType() {
      return BankUseTypeEnum
    }
  },
  watch: {
    isEnableMaxHoldAmount() {
      this.$emit('update', {
        ...this.form,
        maxHoldAmount: this.isEnableMaxHoldAmount ? this.form.maxHoldAmount : 0
      })
    },
    form: {
      handler(val) {
        this.$emit('update', {
          ...val,
          maxHoldAmount: this.isEnableMaxHoldAmount ? val.maxHoldAmount : 0
        })
      },
      deep: true
    }
  },
  created() {
    this.form = {
      minBalance: +this.bankAccountForm.minBalance || 0,
      maxBalance: +this.bankAccountForm.maxBalance || 0,
      maxHoldAmount: +this.bankAccountForm.maxHoldAmount || 0,
      isEnableTransferMinBalance: +this.bankAccountForm.minBalance > 0,
      isEnableTransferMaxBalance: +this.bankAccountForm.maxBalance > 0,
    }
    this.isEnableMaxHoldAmount = this.bankAccountForm.maxHoldAmount > 0
  }
})
</script>

<style scoped>

</style>